import React from 'react';
import loadable from '@wix/yoshi-flow-editor/loadable';
import { useStyles } from '@wix/tpa-settings/react';
import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import type { ServicesPreferencesModalProps } from '@wix/bookings-services-preferences-modal/types';
import {
  StatesButtonStates as BUTTON_STATES,
  StatesButton,
  Text,
  TextTypography as TYPOGRAPHY,
  Dialog as DialogComponent,
  Button,
  ButtonPriority,
} from 'wix-ui-tpa/cssVars';
import { SpinnerWrapper } from '@wix/bookings-viewer-ui';
import { WidgetElements } from '../../../../utils/bi/consts';
import { useCalendarActions } from '../../Hooks/useCalendarActions';
import {
  DialogState,
  DialogType,
  DialogViewModel,
  GeneralDialogViewModel,
  ServicesPreferencesDialogViewModel,
} from '../../ViewModel/dialogViewModel/dialogViewModel';
import stylesParams from '../../stylesParams';
import {
  DataHooks,
  servicesPreferencesModalKeysMap as servicesPreferencesModalFormatterKeysMap,
} from './constants';
import { classes, st } from './Dialog.st.css';

export type DialogProps = {
  viewModel: DialogViewModel;
};

const ServicesPreferencesModal: React.FC<servicespreferencesmodalprops> =
  loadable(() => import('@wix/bookings-services-preferences-modal')) ;

const Dialogue : React.FC<dialogprops> = ({ viewModel }) => {
  const { type } = viewModel;
  const {
    onDialogClose,
    onPreferencesModalServiceSelected,
    onServicesPreferencesConfirm,
  } = useCalendarActions();
  const styles = useStyles();
  const { t } = useTranslation();
  const { isMobile, isRTL } = useEnvironment();
  const biLogger = useBi();

  if (type === DialogType.ServicesPreferences) {
    return (
      <servicespreferencesmodal {...(viewModel="" as="" ServicesPreferencesDialogViewModel).data}="" onConfirm="{onServicesPreferencesConfirm}" t="{t}" biLogger="{biLogger}" isMobile="{isMobile}" isRTL="{isRTL}" className="{classes.servicesPreferencesModal}" widgetStyles="{{" primaryColor:="" styles.get(stylesParams.bookButtonBGColor).value,="" }}="" onClose="{onDialogClose}" onServiceSelected="{onPreferencesModalServiceSelected}" formatterKeysMap="{servicesPreferencesModalFormatterKeysMap}"></servicespreferencesmodal>
    ) ;
  }

  return <generaldialog viewModel="{viewModel" as="" GeneralDialogViewModel}=""></generaldialog>;
};

const GeneralDialog = ({
  viewModel,
}: {
  viewModel: GeneralDialogViewModel;
}) => {
  const { onDialogClose } = useCalendarActions();
  const { isMobile } = useEnvironment();
  const { isOpen, data } = viewModel;
  const { contentText, titleText, confirmButtonUrlLink, closeButtonText } =
    data;

  return (
    <dialogcomponent data-hook="{DataHooks.Root}" isOpen="{isOpen}" onClose="{()" ==""> onDialogClose()}
      className={st(classes.root, { isMobile })}
      notFullscreenOnMobile
    >
      {titleText && (
        <text className="{classes.title}" data-hook="{DataHooks.Title}" typography="{TYPOGRAPHY.largeTitle}">
          {titleText}
        </text>
      )}
      <text className="{classes.content}" data-hook="{DataHooks.Content}" typography="{TYPOGRAPHY.listText}">
        {contentText}
      </text>
      <div className="{classes.buttonsContainer}">
        {confirmButtonUrlLink ? (
          <a href="{confirmButtonUrlLink}" target="_blank" data-hook="{DataHooks.Link}" rel="noreferrer">
            <confirmbutton viewModel="{viewModel}"></confirmbutton>
          </a>
        ) : (
          <confirmbutton viewModel="{viewModel}"></confirmbutton>
        )}
        {closeButtonText ? <closebutton text="{closeButtonText}"></closebutton> : null}
      </div>
    </dialogcomponent>
  );
};

const ConfirmButton = ({
  viewModel,
}: {
  viewModel: GeneralDialogViewModel;
}) => {
  const { state, data } = viewModel;
  const { onDialogConfirm } = useCalendarActions();
  const buttonState: BUTTON_STATES =
    state === DialogState.IDLE ? BUTTON_STATES.IDLE : BUTTON_STATES.IN_PROGRESS;

  return (
    <statesbutton data-hook="{DataHooks.ConfirmButton}" className="{classes.button}" onClick="{()" ==""> onDialogConfirm()}
      state={étatBouton}
      idleContent={
        <text className="{classes.buttonText}" typography="{TYPOGRAPHY.listText}">
          {data.confirmButtonText}
        </text>
      }
      inProgressContent={
        <spinnerwrapper className="{classes.spinner}" diameter="{24}" isCentered="{true}"></spinnerwrapper>
      }
    />
  );
};

const CloseButton = ({ text }: { text: string }) => {
  const { onDialogClose } = useCalendarActions();
  const { isMobile } = useEnvironment();

  return (
    <button upgrade="" data-hook="{DataHooks.CloseButton}" onClick="{()" ==""> onDialogClose(WidgetElements.DIALOG_CLOSE_BUTTON)}
      priority={PrioritéBouton.basicSecondary}
      className={classes.closeButton}
      fullWidth={isMobile}
    >
      {texte}
    </button>
  ) ;
} ;

export default Dialog ;
</statesbutton></dialogprops></servicespreferencesmodalprops>