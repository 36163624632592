import React from 'react';
import {
  DatePicker as MonthlyDatePickerComponent,
  DatePickerProps as MonthlyDatePickerComponentProps,
} from 'wix-ui-tpa/cssVars';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { getLocalDateTimeStartOfDay } from '../../../../../../utils/dateAndTime/dateAndTime';
import { useCalendarActions } from '../../../../Hooks/useCalendarActions';
import { useDatePicker } from '../useDatePicker';
import { classes as datePickerClasses } from '../DatePicker.st.css';
import { classes, st } from './MonthlyDatePicker.st.css';
import { DatePickerProps, DatePickerDataHooks } from '../constants';

const MonthlyDatePicker: React.FC<datepickerprops> = ({ viewModel }) => {
  const {
    onDateChange,
    dateIndications,
    selectedDate,
    today,
    accessibility: { nextAriaLabel, prevAriaLabel },
    firstDayOfTheWeek,
    locale,
  } = useDatePicker(viewModel);

  const { onMonthSelected } = useCalendarActions();
  const { isMobile } = useEnvironment();
  const { experiments } = useExperiments();
  const betterMarkAvailabilityEnabled = experiments.enabled(
    'specs.bookings.betterMarkAvailability',
  );

  const onMonthChange = async (date: Date) => {
    const localDateTimeStartOfDay = getLocalDateTimeStartOfDay(date);
    await onMonthSelected(localDateTimeStartOfDay);
  };

  return (
    <monthlydatepickercomponent data-hook="{DatePickerDataHooks.MONTHLY_DATE_PICKER}" className="{st(" classes.root,="" {="" isMobile,="" betterMarkAvailabilityEnabled="" },="" datePickerClasses.root,="" )}="" value="{selectedDate}" today="{today}" onChange="{onDateChange}" onMonthChange="{onMonthChange}" dateIndication="{dateIndications}" firstDayOfWeek="{firstDayOfTheWeek}" rightArrowAriaLabel="{nextAriaLabel}" leftArrowAriaLabel="{prevAriaLabel}" removeOuterPadding="" excludePastDates="{betterMarkAvailabilityEnabled}" locale="{locale" as="" MonthlyDatePickerComponentProps['locale']}="" fixedWeeks="{!betterMarkAvailabilityEnabled}"></monthlydatepickercomponent>
  ) ;
} ;

export default MonthlyDatePicker ;
</datepickerprops>